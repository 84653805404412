export default {
  "container": "qNb",
  "form-container": "qNy",
  "preview-container": "qNU",
  "credit-note-preview-container": "qNj",
  "credit-note-preview": "qNW",
  "content": "qNu",
  "title": "qvS",
  "tabs-container": "qvc",
  "close-button": "qvq btn btn--icon-only btn--tertiary btn--large",
  "header": "qvZ",
  "without-tabs": "qvR"
};
