export default {
  "container": "qxo",
  "content": "qxn",
  "logos": "qxi",
  "logo": "qxA",
  "animation": "qxY",
  "title": "qxh title-2 mb-32",
  "avatar": "qxf",
  "actions": "qxK mb-32",
  "footer": "qxG body-2"
};
