export default {
  "page-wrapper": "qTw",
  "container": "qTN",
  "spinner-container": "qTv",
  "left-panel": "qTo",
  "form": "qTn",
  "left-content": "qTi",
  "form-footer": "qTA",
  "delete-button": "qTY",
  "header": "qTh",
  "preview-container": "qTf",
  "email-preview": "qTK",
  "close-button": "qTG btn btn--icon-only btn--tertiary btn--large"
};
