export default {
  "guests": "qHA",
  "guests--upsell-screen": "qHY",
  "no-members": "qHh",
  "teams-header": "qHf",
  "empty": "qHK",
  "invite-text": "qHG",
  "body": "qHr",
  "members": "qHb",
  "members-list": "qHy",
  "members-title": "qHU caption-bold",
  "member": "qHj",
  "details": "qHW",
  "kyc-disclaimer": "qHu",
  "kyc-disclaimer-width": "qOS"
};
