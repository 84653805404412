export default {
  "page-container": "qmT",
  "form": "qmz",
  "close-button": "qma btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmH",
  "preview-padding": "qmO",
  "title": "qmm",
  "header": "qmp",
  "without-tabs": "qmx",
  "form-section": "qmw",
  "form-footer": "qmN",
  "disclaimer-text": "qmv"
};
