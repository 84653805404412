export default {
  "mandates": "qpO",
  "mandates-empty": "qpm",
  "header": "qpp",
  "header-empty": "qpx",
  "header-content": "qpw",
  "search": "qpN",
  "search-bar": "qpv",
  "body": "qpo",
  "isEmpty": "qpn",
  "left-section": "qpi",
  "mandates-list": "qpA",
  "mandate-suspended": "qpY",
  "list-title": "qph caption-bold",
  "list-empty": "qpf",
  "empty-illustration": "qpK",
  "empty-title": "qpG title-3",
  "empty-description": "qpr body-2",
  "body-details": "qpb",
  "l-app-content__page-header": "qpy",
  "pagination-footer": "qpU"
};
