export default {
  "accounts": "qDf",
  "header-subtitle": "qDK title-4",
  "subtitle-container": "qDG",
  "subtitle": "qDr title-3 mb-16",
  "amount": "qDb body-2",
  "recommendations-section": "qDy",
  "dismissing": "qDU",
  "recommendations-divider": "qDj",
  "list--cashbeeSavings": "qDW",
  "list": "qDu",
  "icon": "qVS",
  "badge": "qVc",
  "discover-tile": "qVq"
};
