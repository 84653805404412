export default {
  "body": "qpj",
  "members": "qpW",
  "members-results": "qpu",
  "members-list": "qxS",
  "members-title": "qxc caption-bold",
  "member": "qxq",
  "empty-illustration": "qxZ",
  "empty-title": "qxR title-3",
  "empty-description": "qxQ body-2",
  "invitable-members": "qxe",
  "details": "qxB",
  "pagination-footer": "qxE"
};
