export default {
  "page-wrapper": "qTc",
  "back-button": "qTq",
  "abort-button": "qTZ",
  "container": "qTR",
  "subtitle": "qTQ",
  "button": "qTe",
  "panel-list": "qTB",
  "panel-list-item": "qTE",
  "card-illustration": "qTd"
};
