export default {
  "page-wrapper": "qDk",
  "container": "qDJ",
  "sidebar": "qDs",
  "search-input": "qDF",
  "organizations-list": "qDg",
  "content": "qDD",
  "no-result": "qDV",
  "lottie-illustration": "qDL",
  "accounting-header": "qDT",
  "logo": "qDz",
  "accounting-title": "qDa"
};
