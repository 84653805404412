export default {
  "team": "qxd",
  "no-members": "qxI",
  "teams-header": "qxt",
  "header-search": "qxM",
  "search-bar": "qxP",
  "invite": "qxl body-2",
  "invite-text": "qxX",
  "empty-illustration": "qxC",
  "empty-title": "qxk title-3",
  "empty-description": "qxJ body-2",
  "pagination-footer": "qxs",
  "filter-search": "qxF",
  "fullsize": "qxg",
  "tabs-container": "qxD",
  "tabs-separator": "qxV",
  "tooltip-wrapper": "qxL",
  "invite-cta": "qxT",
  "kyc-disclaimer": "qxz",
  "kyc-disclaimer-width": "qxa"
};
