export default {
  "bank-accounts-show": "qDH",
  "header": "qDO l-app-content__page-header",
  "share-and-certificate": "qDm",
  "share": "qDp",
  "certificate": "qDx",
  "section-divider": "qDw",
  "content-wrapper": "qDN",
  "content": "qDv"
};
