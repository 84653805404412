export default {
  "member-details": "qHd",
  "header": "qHI",
  "header-img": "qHt",
  "invited-persona": "qHM",
  "revoked-persona": "qHP",
  "invited-icon": "qHl",
  "revoked-icon": "qHX",
  "dropdown": "qHC",
  "header-body": "qHk",
  "name": "qHJ title-3",
  "email": "qHs caption-bold",
  "actions": "qHF body-2",
  "actions-cards": "qHg",
  "actions-transactions": "qHD",
  "body": "qHV",
  "expense-permissions-section": "qHL",
  "body-title": "qHT title-4",
  "body-attr": "qHz",
  "body-label": "qHa",
  "attr-buttons": "qHH",
  "body-role": "qHO small",
  "details-actions": "qHm",
  "actions-item": "qHp",
  "disclaimer": "qHx",
  "permissions": "qHw",
  "permission": "qHN",
  "permission-label": "qHv",
  "permission-check": "qHo",
  "permission-missing": "qHn",
  "editable-section": "qHi"
};
