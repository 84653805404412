export default {
  "page": "qvm",
  "container": "qvp",
  "form-container": "qvx",
  "preview-container": "qvw",
  "pdf-preview-container": "qvN",
  "content": "qvv",
  "title": "qvo",
  "tabs-container": "qvn",
  "close-button": "qvi btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvA",
  "header": "qvY",
  "without-tabs": "qvh"
};
