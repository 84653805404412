export default {
  "page-container": "qmh",
  "form": "qmf",
  "close-button": "qmK btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmG",
  "preview-padding": "qmr",
  "title": "qmb",
  "header": "qmy",
  "without-tabs": "qmU",
  "form-section": "qmj",
  "form-footer": "qmW",
  "disclaimer-text": "qmu"
};
