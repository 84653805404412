export default {
  "header": "qDq",
  "btn-preset": "qDZ btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qDR",
  "statements-table": "qDQ",
  "no-result": "qDe",
  "lottie-illustration": "qDB",
  "wrapper": "qDE",
  "access-denied": "qDd",
  "access-denied-illustration": "qDI",
  "application-card": "qDt",
  "presets-wrapper": "qDM",
  "error-wrapper": "qDP",
  "error-container": "qDl",
  "error-illustration": "qDX mb-32",
  "invoicing-info": "qDC"
};
