export default {
  "create-invoice-button": "qoc btn btn--primary",
  "filters-container": "qoq",
  "header-actions": "qoZ",
  "product-discovery": "qoR",
  "search-field": "qoQ",
  "full-width": "qoe",
  "footer-import-cta": "qoB",
  "footer-show-existing-invoices-cta": "qoE"
};
