export default {
  "splash-landing": "qLg",
  "container": "qLD",
  "options": "qLV",
  "physical": "qLL",
  "card-label": "qLT",
  "physical-cta-container": "qLz",
  "physical-cta": "qLa",
  "coming-soon": "qLH"
};
