export default {
  "container": "qxr",
  "content": "qxb",
  "logos": "qxy",
  "logo": "qxU",
  "animation": "qxj",
  "title": "qxW title-2 mb-32",
  "avatar": "qxu",
  "dropdown": "qwS",
  "disabled": "qwc",
  "organization": "qwq",
  "actions": "qwZ"
};
