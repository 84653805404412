export default {
  "container": "qOo",
  "caption": "qOn caption",
  "compact-header": "qOi",
  "visible": "qOA",
  "page-container": "qOY",
  "main": "qOh",
  "tabs": "qOf",
  "tabs-nav": "qOK",
  "tabs-panels": "qOG",
  "tabs-panel": "qOr body-2",
  "sidebar": "qOb",
  "cms-content": "qOy"
};
