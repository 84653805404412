export default {
  "main-container": "qLi",
  "main": "qLA",
  "main-wrapper": "qLY",
  "close-button": "qLh",
  "active-block": "qLf",
  "title": "qLK",
  "list-options": "qLG",
  "card": "qLr",
  "card-container": "qLb",
  "card-image": "qLy",
  "card-tiles": "qLU"
};
