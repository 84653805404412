import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Button } from '@repo/design-system-kit';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/upcoming-transactions';
import { UpcomingTransactions } from 'qonto/react/components/cash-flow/components/upcoming-transactions';
import { PeriodSelector } from 'qonto/react/components/cash-flow/components/upcoming-transactions/period-selector/period-selector';
import { TransactionTypeSelector } from 'qonto/react/components/cash-flow/components/upcoming-transactions/transaction-type/transaction-type-selector';
import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';
export default class UpcomingTransactionsIndexController extends Controller {
  @service intl;
  @service emptyStates;
  @service abilities;
  @service segment;
  @tracked shouldShowEmptyState = false;
  @tracked displayFormModal = false;
  @tracked selectedTransactionType = 'both';
  @tracked selectedPeriod = '30';

  upcomingTransactions = UpcomingTransactions;
  transactionTypeSelector = TransactionTypeSelector;
  button = Button;
  periodSelector = PeriodSelector;
  errorState = ErrorState;

  get emptyStateOptions() {
    let options = this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl),
      abilities: {
        canView: this.abilities.canViewUpcomingTransactions,
      },
    });

    return options;
  }

  get bankAccounts() {
    return this.model.bankAccounts;
  }

  get shouldShowAddTransactionCta() {
    return this.abilities.can('use upcoming transactions actions cash-flow');
  }

  @action
  showEmptyState(isEmpty) {
    this.shouldShowEmptyState = isEmpty;
  }

  @action
  toggleModal() {
    this.displayFormModal = !this.displayFormModal;
    if (this.displayFormModal) {
      this.segment.track('compass_manual-entry_cta-clicked');
    }
  }

  @action
  handleHideFormModal() {
    this.displayFormModal = false;
  }

  @action onTransactionTypeChange(type) {
    this.selectedTransactionType = type;
  }

  @action onPeriodChange(period) {
    this.selectedPeriod = period;
  }
}
