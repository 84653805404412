export default {
  "details": "qpM",
  "header": "qpP",
  "avatar": "qpl mr-16",
  "header-beneficiary": "qpX",
  "header-beneficiary-activity": "qpC",
  "header-beneficiary-details": "qpk",
  "header-beneficiary-details-recurring": "qpJ",
  "infos": "qps",
  "infos-title": "qpF",
  "infos-empty": "qpg",
  "infos-list": "qpD",
  "infos-list-item": "qpV",
  "infos-list-item-left": "qpL",
  "infos-list-item-right": "qpT",
  "infos-list-item-attachment": "qpz",
  "infos-transactions": "qpa",
  "actions": "qpH"
};
