export default {
  "page": "qvt",
  "container": "qvM",
  "form-container": "qvP",
  "preview-container": "qvl",
  "pdf-preview-container": "qvX",
  "content": "qvC",
  "title": "qvk",
  "tabs-container": "qvJ",
  "close-button": "qvs btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvF",
  "header": "qvg",
  "without-tabs": "qvD"
};
