export default {
  "page": "qNL",
  "container": "qNT",
  "form-container": "qNz",
  "preview-container": "qNa",
  "pdf-preview-container": "qNH",
  "content": "qNO",
  "header": "qNm",
  "close-button": "qNp btn btn--icon-only btn--tertiary btn--large"
};
