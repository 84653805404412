export default {
  "container": "qaL",
  "page-wrapper": "qaT",
  "header": "qaz",
  "back-button-wrapper": "qaa",
  "header-inner": "qaH",
  "header-main-wrapper": "qaO",
  "header-main": "qam",
  "header-right": "qap",
  "content-wrapper": "qax",
  "content": "qaw",
  "content-body": "qaN",
  "sidebar": "qav"
};
